import React, { useState } from 'react';
import styles from './PromptGuidance.module.css';
import { ArrowUp28Regular, ArrowDown28Regular, Dismiss24Regular } from "@fluentui/react-icons";

interface Props {
    isVisible: boolean;
    toggleVisibility: () => void;
    onPromptSelect: (text: string) => void;
    profile: string;
}

export const PromptGuidance: React.FC<Props> = ({ isVisible, toggleVisibility, onPromptSelect, profile }) => {
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    const togglePopup = () => {
        if (isPopupVisible) {
            setIsClosing(true);
            setTimeout(() => {
                setPopupVisible(false);
                setIsClosing(false);
            }, 200);
        } else {
            setPopupVisible(true);
        }
    };

    const handleCardClick = (prompt: string) => {
        // Use a regular expression to remove <strong> tags
        onPromptSelect(prompt.replace(/<\/?strong>/gi, ""));
        setIsClosing(true);
        setTimeout(() => {
            setPopupVisible(false);
            setIsClosing(false);
        }, 200);
    };

    const examplePrompts = (profile === "coopgptppl" || profile === "coopgptppl2") ? [
        "List the names of Fonterra's Global Polices and provide a short summary of each",
        "Where do I find more information about Fonterra's  policies or standards regarding \n<strong>[enter topic - the more detail the better the response]</strong>",
        "What are Fonterra's values?",
        "When do I need to declare a conflicts of interest?",
        "I have been given a gift valued at USD <strong>$[enter amount]</strong>, do I need to declare it",
        "What are the 4 levels of information sensitivity?",
        "Which Fonterra standards cover the requirements of food safety?",
        "I am a health and safety rep. What are my responsibilities?",
        "When do I need to report a privacy breach"
    ] : [
        "Proofread the following text and outline any issues with spelling, grammar, or clarity: \n<strong>[insert text]</strong>",
        "Summarise the following text: \n<strong>[insert text]</strong>",
        "Write me a professional email based on the following bullet points: \n<strong>[insert bullet points]</strong>",
        "I'm working on a research project about <strong>[insert topic]</strong>. Can you suggest some potential research questions or hypotheses?",
        "Can you summarise the key findings and arguments of the following research paper: \n<strong>[insert text]</strong>",
        "Can you explain the concept of <strong>[insert complex concept]</strong> in simple terms?",
        "Can you translate this <strong>[insert language]</strong> phrase to English?",
        "Find bugs in this code: \n<strong>[insert code]</strong>",
        "Explain this code step by step: \n<strong>[insert code]</strong>"
    ];

    return (
        <div
            className={`${styles.promptGuidance} ${isVisible ? styles.expanded : styles.collapsed}`}
        >
            <div className={styles.headingContainer} onClick={toggleVisibility}>
                <h3>Prompt Guidance</h3>
                {isVisible ? (
                    <ArrowDown28Regular className={styles.iconAdjust} />
                ) : (
                    <ArrowUp28Regular className={styles.iconAdjust} />
                )}
            </div>
            <p>Try using the following sentence to get started...</p>
            <p>
                <strong>As a</strong> (ROLE i.e. analyst, product owner, marketer, consultant)<br /><br />
                <strong>I am</strong> (END GOAL i.e. designing a..., creating a..., writing a...).<br /><br />
                <strong>I would like to</strong> (TASK i.e. summarise a document, generate ideas, gather information on..., create a...)<br /><br />
                <strong>so that I can</strong> (OUTCOME i.e. improve my communication, generate more interest, present to stakeholders).
            </p>
            <div className={styles.exampleText} onClick={togglePopup}>
                View Example Prompts
            </div>
            {isPopupVisible && (
                <div className={`${styles.popup} ${isClosing ? styles.closing : ''}`}>
                    <Dismiss24Regular className={styles.closeIcon} onClick={togglePopup} />
                    <h2 className={styles.popupHeader}>Select a prompt to use</h2>
                    <div className={styles.cardContainer}>
                        {examplePrompts.map((prompt, index) => (
                            <div
                                key={index}
                                className={styles.card}
                                onClick={() => handleCardClick(prompt)}
                                dangerouslySetInnerHTML={{ __html: prompt }}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};
